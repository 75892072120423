import React, { useRef } from 'react';
import { ReactComponent as CameraIcon } from '../../images/icons/camera.svg';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import styles from './ImageUploadMobileWidget.module.scss';

const ImageUploadMobileWidget = ({ className, closePopup, onImageSearchFileChange }) => {
  const imageInputRef = useRef(null);

  const clearUploadClosePopup = () => {
    imageInputRef.current.value = '';
    closePopup();
  };

  return (
    <>
      <label htmlFor="pugInputUpload" className={classNames(styles.root, className)}>
        <CameraIcon />
        <span>
          <FormattedMessage id="search.searchByImage" defaultMessage="Search by image" />
        </span>
      </label>
      <input
        id="pugInputUpload"
        className={classNames(styles.inputFile)}
        type="file"
        name="map_file"
        ref={imageInputRef}
        onChange={onImageSearchFileChange(clearUploadClosePopup)}
        accept=".jpg, .jpeg, .png"
        style={{ display: 'none !important' }}
      />
    </>
  );
};

export default ImageUploadMobileWidget;
